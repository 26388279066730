import { toCamelCase } from '../../common/config/utils';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import env from './variables';

const { FB_PIXEL_ID, FB_CONVERSIONS_API_TOKEN, FB_TEST_EVENT_CODE } = env;

const defaultHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

const request = (url, opts = {}) => new Promise(async (resolve, reject) => {
  const endpoint = url;
  window.fetch(endpoint, { ...opts, ...defaultHeaders() }).then((response) => {
    if (response.ok) {
      if (response.statusText === 'No Content') {
        resolve(toCamelCase({ success: 'ok' }));
        return;
      }
      response.json().then((responseJson) => {
        log(LOG_LEVELS.INFO, 'FbAPI', `URL ${ url } request success`);
        resolve(toCamelCase(responseJson));
      }).catch((error) => {
        log(LOG_LEVELS.ERROR, 'FbAPI', `URL ${ url } error parsing responce with error ${ error }`);
        reject(error);
      });
    }
  }).catch((error) => {
    log(LOG_LEVELS.ERROR, 'FbAPI', `URL ${ url } request error ${ error }`);
    reject(error);
  });
});

export const trackFBServerEventAPI = (data) => {
  log(LOG_LEVELS.DEBUG, 'FbAPI', 'trackFBServerEvent', data);
  // for testing on pixel test tools add &test_event_code=code
  const API_VERSION = 'v11.0';
  const url = `https://graph.facebook.com/${ API_VERSION }/${ FB_PIXEL_ID }/events?access_token=${ FB_CONVERSIONS_API_TOKEN }&data=${ JSON.stringify(data) }&test_event_code=${ FB_TEST_EVENT_CODE }`;
  return request(url, { method: 'POST' });
};
