import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  errorCode: null,
  errorText: null,
  errorUrl: null,
  statusText: null
});

const applicationErrorReducer = createReducer(getDefaultState(), {
  [Const.applicationError.error]: (state, {
    errorCode, errorText, errorUrl, statusText
  }) => ({
    ...state,
    errorCode,
    errorText,
    errorUrl,
    statusText
  }),
  [Const.applicationError.clearError]: () => ({
    ...getDefaultState()
  })
});

export default applicationErrorReducer;
