import { generateDynamicRoutesTranslations, LANGUAGES } from './common';
import en from '../translations/en.json';
import es from '../translations/es.json';

export const SUPPORTED_LANGUAGES = [LANGUAGES.en, LANGUAGES.es];
export const DEFAULT_LANGUAGE = LANGUAGES.en;

const extraEN = require('../translations/en_pg.json');
const extraES = require('../translations/es_pg.json');

export const initResources = {
  [LANGUAGES.en]: {
    translation: { ...en, ...extraEN, dynamicRoutes: { ...generateDynamicRoutesTranslations(LANGUAGES.en) } }
  },
  [LANGUAGES.es]: {
    translation: { ...es, ...extraES, dynamicRoutes: { ...generateDynamicRoutesTranslations(LANGUAGES.es) } }
  }
};
