import { generateDynamicRoutesTranslations, LANGUAGES } from './common';
import en from '../translations/en.json';

export const SUPPORTED_LANGUAGES = [LANGUAGES.en];
export const DEFAULT_LANGUAGE = LANGUAGES.en;

const extraEN = require('../translations/en_ks.json');

export const initResources = {
  [LANGUAGES.en]: {
    translation: { ...en, ...extraEN, dynamicRoutes: { ...generateDynamicRoutesTranslations(LANGUAGES.en) } }
  }
};
