import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = () => ({
  options: null,
  loading: false,
  skipNextLoad: false
});

const handler = {
  [Const.paymentOptions.load]: () => ({
    options: [],
    loading: true
  }),
  [Const.paymentOptions.loaded]: (state, { options, skipNextLoad }) => ({
    ...state,
    options,
    loading: false,
    skipNextLoad
  }),
  [Const.paymentOptions.reset]: (state) => ({
    ...state,
    options: [],
    loading: false,
    skipNextLoad: false
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState()
  }),
  [Const.applicationError.clearError]: () => ({
    ...defaultState()
  }),
  [Const.paymentSources.loaded]: (state, { options }) => ({
    ...state,
    options: options ?? state.options
  })
};

export default createReducer(defaultState(), handler);
