import pt from '../translations/pt.json';
import es from '../translations/es.json';
import { generateDynamicRoutesTranslations, LANGUAGES } from './common';

export const SUPPORTED_LANGUAGES = [LANGUAGES.es, LANGUAGES.pt];
export const DEFAULT_LANGUAGE = LANGUAGES.es;

const extraPT = require('../translations/pt_ps.json');
const extraES = require('../translations/es_ps.json');

export const initResources = {
  [LANGUAGES.pt]: {
    translation: { ...pt, ...extraPT, dynamicRoutes: { ...generateDynamicRoutesTranslations(LANGUAGES.pt) } }
  },
  [LANGUAGES.es]: {
    translation: { ...es, ...extraES, dynamicRoutes: { ...generateDynamicRoutesTranslations(LANGUAGES.es) } }
  }
};
