import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  user: null,
  impersonated: null,
  userMPIdentify: false,
  paymentProcessingSequence: 0,
  analyticProps: null
};

const handler = {
  [Const.auth.success]: (
    state,
    {
      payload: {
        user: { user }
      }
    }
  ) => ({
    ...state,
    user
  }),
  [Const.introduce.user]: (
    state,
    {
      payload: {
        user: { user }
      }
    }
  ) => ({
    ...state,
    user
  }),
  [Const.user.user]: (
    state,
    {
      payload: {
        user: { user }
      }
    }
  ) => ({
    ...state,
    loading: false,
    error: null,
    paymentProcessingSequence: user.paymentProcessing === false ? 0 : state.paymentProcessingSequence,
    user: {
      ...state.user,
      ...user
    }
  }),
  [Const.user.loading]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [Const.user.error]: (state, { payload: error }) => ({
    ...state,
    error,
    loading: false
  }),
  [Const.user.impersonated]: (state, { impersonated }) => ({
    ...state,
    impersonated
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  }),
  [Const.user.MPIdentify]: (state) => ({
    ...state,
    userMPIdentify: true
  }),
  [Const.user.updateUserData]: (state, { user }) => ({
    ...state,
    user: { ...state.user, ...user }
  }),
  [Const.user.updatePaymentProcessingSequence]: (state) => ({
    ...state,
    paymentProcessingSequence: state.paymentProcessingSequence + 1
  }),
  [Const.user.analyticProps]: (state, { analyticProps }) => ({
    ...state,
    analyticProps
  })
};

export default createReducer(defaultState, handler);
