import { log, LOG_LEVELS } from '../../common/config/app_logger';

let hidden,
  visibilityChange,
  callbackMethod;

const handleVisibilityChange = () => {
  callbackMethod(!document[hidden]);
};

const initVisibility = () => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
};

export const removeCallback = () => {
  document.removeEventListener(visibilityChange, handleVisibilityChange, false);
  callbackMethod = null;
};

export const setVisibilityChangeCallback = callback => {
  if (!hidden) {
    initVisibility();
  }
  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    log(LOG_LEVELS.ERROR, 'Vsibility', 'The browser does not support the Visibility API');
  } else {
    // Handle page visibility change
    removeCallback();
    callbackMethod = callback;
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }
};
