import {
  advisorUpdated, advisorsListUpdated, userUpdated, setAnalyticsProps
} from '../actions/shared_actions';

export const refreshReducersFromResponse = (res, { ignoreUserUpdate = false } = {}) => {
  const {
    user, advisor, advisors, analyticProps
  } = res;
  if (!ignoreUserUpdate && user) {
    window.store.dispatch(userUpdated(user));
  }
  if (advisor) {
    window.store.dispatch(advisorUpdated(advisor));
  }
  if (advisors) {
    window.store.dispatch(advisorsListUpdated(advisors));
  }
  if (analyticProps) {
    window.store.dispatch(setAnalyticsProps(analyticProps));
  }
};
