/* eslint-disable no-unused-vars */
import { formatISO } from 'date-fns';
import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';
import { UAParser } from 'ua-parser-js';
import { SignJWT } from 'jose';
import { getDataFromStorage, saveDataToStorage } from '../../app/config/util';
import { isKA } from './utils';
import env from '../../app/config/variables';
/* eslint-disable-next-line import/no-cycle */
import { getDeviceId } from '../../app/config/device_id';

import 'navigator.locks';

const storageKey = 'logs_batch';
const maxRecords = 200;
const sendInterval = 60 * 1000; // 60 sec

let endpoint = '';
let secret = '';
let initialized = false;

const generateJwt = () => {
  const encodedSecret = new TextEncoder().encode(secret);

  return new SignJWT()
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('1m')
    .sign(encodedSecret);
};

const sendLogsToServer = (jwt, entity) => window.fetch(endpoint, {
  method: 'POST',
  headers: {
    Authentication: `Bearer ${ jwt }`,
    'Content-Type': 'text/plain'
  },
  credentials: 'include',
  body: JSON.stringify(entity)
});

const prepareBatchAndSend = async (events) => {
  if (!initialized) return;

  try {
    const parser = new UAParser();
    const parserResult = parser.getResult();

    const [deviceId, jwt] = await Promise.all([getDeviceId(), generateJwt()]);

    const batch = {
      device_id: deviceId,
      app_version: env.CLIENT_APP_VERSION,
      batch_id: uuidv4(),
      device_lang: navigator.language.substring(0, 2),
      device_os: parserResult.os.name,
      os_vesion: parserResult.os.version,
      user_id: window.currentUserId || 0,
      /* eslint-disable-next-line no-underscore-dangle */
      mixpanel_distinct_id: mixpanel.__loaded ? mixpanel.get_distinct_id() : null,
      events
    };

    if (endpoint) {
      const response = await sendLogsToServer(jwt, batch);

      if (response.ok) {
        saveDataToStorage(storageKey, JSON.stringify([]));
      }
    }
  } catch (e) {
    // We don't need to fall because of error, just try after 1 minute
  }
};

export const sendLogsIfNeed = () => {
  navigator.locks.request(storageKey, async (lock) => {
    const storageLogs = JSON.parse(getDataFromStorage(storageKey));
    if (storageLogs?.length) await prepareBatchAndSend(storageLogs);
  });
};

const saveLogToStorage = (log) => {
  navigator.locks.request(storageKey, async (lock) => {
    const storageLogs = JSON.parse(getDataFromStorage(storageKey)) || [];
    const updatedArray = [...storageLogs, log];
    saveDataToStorage(storageKey, JSON.stringify(updatedArray));

    if (updatedArray.length >= maxRecords) {
      await prepareBatchAndSend(updatedArray);
    }
  });
};

const processLogParameters = (message, index) => {
  const defaultKey = index === 0 ? 'message' : `param_${ index }`;

  if (typeof message !== 'object') return { [defaultKey]: message };
  if (!(message instanceof Error)) return message;

  return {
    error: Object.getOwnPropertyNames(message)
      .map(x => ({ [x]: message[x] }))
      .reduce((prev, cur) => ({ ...prev, ...cur }), {})
  };
};

const handleLog = (level, subsystem, message) => {
  const now = new Date();

  const context = {
    subsystem
  };

  for (let i = 0; i < message.length; ++i) {
    Object.assign(context, processLogParameters(message[i], i));
  }

  const log = {
    insert_id: uuidv4(),
    level,
    tag: isKA() ? 'Kasamba' : 'Purple Garden',
    device_time: formatISO(now),
    utc_time: now.toISOString(),
    context
  };

  saveLogToStorage(log);
};

export const LOG_LEVELS = Object.freeze({
  VERBOSE: 'verbose',
  DEBUG: 'debug',
  INFO: 'info',
  ERROR: 'error',
  NOTHING: 'nothing'
});

export const initLogger = (loggerEndpoint, loggerSecret) => {
  endpoint = loggerEndpoint;
  secret = loggerSecret;
  initialized = true;
};

export const log = (level, subsystem, ...message) => {
  // Validate log level
  if (!Object.values(LOG_LEVELS).includes(level)) {
    level = LOG_LEVELS.NOTHING; // Default to NOTHING if invalid level provided
  }

  handleLog(level, subsystem, message);
  Bugsnag.leaveBreadcrumb(message);
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(level, subsystem, message);
  }
};

window.addEventListener('beforeunload', () => {
  sendLogsIfNeed();
});

setInterval(sendLogsIfNeed, sendInterval);
