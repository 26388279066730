import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = () => ({
  existing: null,
  available: null,
  loading: false,
  current: null,
  loaded: false,
  addingCardData: {
    config: {},
    error: null
  },
  managingSource: null
});

const handler = {
  [Const.paymentSources.load]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [Const.paymentSources.done]: (state) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null
  }),
  [Const.paymentSources.manage]: (state, { id }) => ({
    ...state,
    managingSource: id ? state.existing.find((s) => s.id === id) : null
  }),
  [Const.paymentSources.edit]: (state, { id }) => ({
    ...state,
    // TODO: save gateway here
    addingCardData: { ...state.addingCardData, id }
  }),
  [Const.paymentSources.loaded]: (state, { existing, available }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    existing,
    available,
    current: existing.find((s) => s.isDefault),
    managingSource: null
  }),
  [Const.paymentSources.addingCardData]: (state, { config }) => ({
    ...state,
    addingCardData: { ...state.addingCardData, config },
    loading: false
  }),
  [Const.paymentSources.addingCardError]: (state, { error }) => ({
    ...state,
    addingCardData: { ...state.addingCardData, error }
  }),
  [Const.paymentSources.clearAddingCardError]: (state) => ({
    ...state,
    addingCardData: { ...state.addingCardData, error: null }
  }),
  [Const.paymentSources.doneAddCard]: (state) => ({
    ...state,
    addingCardData: {
      config: {},
      error: null
    },
    loading: false
  }),
  [Const.logOut.clear]: () => defaultState(),
  [Const.applicationError.clearError]: (state) =>  ({
    ...state,
    loading: false
  })
};

export default createReducer(defaultState(), handler);
