import { firebaseConfig } from '../config/variables';

let firebaseAppInstance;

export const initFirebaseOrGetFBApp = (callback) => {
  navigator.locks.request('firebaseAppInstance', async () => {
    if (!firebaseAppInstance) {
      firebaseAppInstance = await import('firebase/app');
    }
    const { initializeApp, getApps } = firebaseAppInstance;
    const apps = getApps();
    const app = apps.length ? apps[0] : initializeApp(firebaseConfig);
    callback(app);
  });
};
