import { toCamelCase } from '../../../common/config/utils';

export const generateDynamicRoutesTranslations = (lang: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { routes } = (window as any).config.uiSettings;
  return Object.fromEntries(
    Object.keys(routes).map((key: string) => [toCamelCase(key), (routes[key].translations?.[lang].slug || routes[key].slug || key)])
  );
};

// eslint-disable-next-line no-shadow
export const enum LANGUAGES {
  en = 'en',
  es = 'es',
  pt = 'pt'
}
